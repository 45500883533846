<!-- =========================================================================================
  File Name: AddNewDataSidebar.vue
  Description: Add New Data - Sidebar component
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary"
    class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
    <div class="mt-6 flex items-center justify-between px-6">
      <h4>
        {{ Object.entries(this.data).length === 0 ? "ADD NEW" : "UPDATE" }} ITEM
      </h4>
      <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
    </div>

    <vs-divider class="mb-0"></vs-divider>

    <VuePerfectScrollbar class="scroll-area--data-list-add-new" :settings="settings">
      <div class="p-6">
        <!-- NAME -->
        <vs-input :label="$t('NameEn')" v-model="NameEN" class="mt-5 w-full" name="nameen" v-validate="'required'" />
        <span class="text-danger text-sm" v-show="errors.has('nameen')">{{
          errors.first("name_en")
        }}</span>

        <vs-input :label="$t('NameAr')" v-model="NameAR" class="mt-5 w-full" name="namear" v-validate="'required'" />
        <span class="text-danger text-sm" v-show="errors.has('namear')">{{
          errors.first("name_ar")
        }}</span>


        <div class="mt-5 flex-1">
          <label class="text-sm opacity-75"> {{ $t("ParentSpecialty") }}
          </label>
          <v-select label="Name" class="w-full" v-model="ParentSpecialtyID" :options="specialities"
            :reduce="ID => ID.ID" />
        </div>
        <div class="mt-5 flex-1">
          <div class="row">
            <div class="col">
              <vs-radio :vs-value=true color="success " class="mt-8" v-model="IsHelper" @change="ChangeTypeOfSpec(1)">{{
                $t("IsHelper")
              }}</vs-radio>
              <!-- :IsGenreal.value="0"  -->

            </div>
          <div class="col">
            <vs-radio :vs-value=true color="success " class="mt-8" v-model="IsGenreal" @change="ChangeTypeOfSpec(0)">{{
              $t("IsGeneral")
            }}</vs-radio>
            <!-- :IsHelper.value="0" -->

          </div>

          <!-- <vs-radio
          v-model="IsHelper"
          :vs-value="true"
          class="mr-4"
          vs-name="IsHelper"
          >IsHelper
        </vs-radio>
        <vs-radio
          v-model="IsHelper"
          :vs-value="false"
          class="mr-4"
          vs-name="IsHelper"
          >unHelper
        </vs-radio>

        <vs-radio
          v-model="IsGeneral"
          :vs-value="true"
          class="mr-4"
          vs-name="IsGeneral"
                    >IsGeneral
                  </vs-radio>
                  <vs-radio
                    v-model="IsGeneral"
                    :vs-value="false"
                    class="mr-4"
                    vs-name="IsGeneral"
                    >unGeneral
                  </vs-radio> -->
          </div>

        </div>
      </div>

      <vs-card title="icon" class="mt-5">
        <h4>{{ $t("Image") }}</h4>
        <template v-if="PhotoPath">
          <div class="img-container w-64 mx-auto flex items-center justify-center">
            <img :src="baseURL + PhotoPath" alt="img" class="responsive" />
          </div>
        </template>
        <div class="modify-img flex justify-between mt-5">
          <input type="file" class="hidden" ref="updateImgInput" @change="updateCurrImg" accept="image/*" />
          <vs-button class="mr-4" type="flat" @click="$refs.updateImgInput.click()">{{ $t("UpdateImage") }}</vs-button>
          <vs-button type="flat" color="#999" @click="PhotoPath = null">{{
            $t("RemoveImage")
          }}</vs-button>
        </div>
      </vs-card>

    </VuePerfectScrollbar>

    <div class="flex flex-wrap items-center p-6" slot="footer">
      <vs-button class="mr-6" @click="submitData" :disabled="!isFormValid">{{
        $t("Save")
      }}</vs-button>
      <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">{{ $t("Cancel") }}</vs-button>
    </div>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { domain } from "@/gloabelConstant.js";
export default {
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      default: () => { }
    }
  },

  watch: {
    isSidebarActive(val) {
      if (!val) return;
      if (Object.entries(this.data).length === 0) {
        this.initValues();
        this.$validator.reset();
      } else {
        let { ID, NameEN, NameAr, ParentSpecialtyID, PhotoPath, IsHelper, IsGenreal } = JSON.parse(
          JSON.stringify(this.data)
        );


        this.ID = ID;
        this.NameEN = NameEN;
        this.NameAR = NameAr;
        this.PhotoPath = PhotoPath;
        this.ParentSpecialtyID = ParentSpecialtyID;
        this.IsGenreal = IsGenreal;
        this.IsHelper = IsHelper;
        debugger
      }
      // Object.entries(this.data).length === 0 ? this.initValues() : { this.ID, this.Name, this.dataCategory, this.dataOrder_status, this.dataPrice } = JSON.parse(JSON.stringify(this.data))
    }
  },

  data() {
    return {
      PhotoPath: "",
      baseURL: domain,
      uploadUrl: domain + "API/Common/UploadFile",
      ID: null,
      NameEN: "",
      NameAR: "",
      IsGenreal: "",
      IsHelper: "",
      ParentSpecialtyID: "",
      settings: {
        // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: 0.6
      }
    };
  },

  computed: {
    specialities() {
      return this.$store.state.SpecialtyList.specialities;
    },
    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive;
      },
      set(val) {
        if (!val) {
          this.$emit("closeSidebar");
          // this.$validator.reset()
          // this.initValues()
        }
      }
    },
    isFormValid() {
      return !this.errors.any() && this.NameEN;
    }
  },

  methods: {
    updateCurrImg(input) {
      this.$store.dispatch("updateCurrImg", input).then(response => {
        this.PhotoPath = response.data;

      });

    },

    ChangeTypeOfSpec(input) {
      if (input == true) {
        this.IsGenreal = false
      }
      else {
        this.IsHelper = false
      }
    },

    initValues() {
      if (this.data.ID) return;
      this.ID = null;
      this.NameAR = "";
      this.NameEN = "";
      this.PhotoPath = "";
      this.ParentSpecialtyID = "";
      this.IsHelper = false;
      this.IsGenreal = false;
    },

    submitData() {
      this.$validator.validateAll().then(result => {
        if (result) {
          const obj = {
            ID: this.ID,
            NameEN: this.NameEN,
            NameAR: this.NameAR,
            PhotoPath: this.PhotoPath,
            ParentSpecialtyID: this.ParentSpecialtyID,
            IsGenreal: this.IsGenreal,
            IsHelper: this.IsHelper
          };
          var x = obj;
          debugger
          if (this.ID !== null && this.ID >= 0) {
            this.$store
              .dispatch("SpecialtyList/UpdateSpecialty", obj)
              .catch(err => {
                console.error(err);
                window.showError(err.response.data.message);
              });
          } else {
            delete obj.ID;
            obj.popularity = 0;
            this.$store
              .dispatch("SpecialtyList/AddSpecialty", obj)
              .catch(err => {
                console.error(err);
                window.showError(err.response.data.message);
              });
          }

          this.$emit("closeSidebar");
          this.initValues();
          //this.$store.dispatch("foodTypeList/fetchfoodTypeListItems");
        }
      });
    }
  },

  components: {
    VuePerfectScrollbar
  }
};
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  height: calc(100% - 4.3rem);
}
</style>
